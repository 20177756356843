import * as client_hooks from '../../../src/hooks.client.js';
import * as universal_hooks from '../../../src/hooks.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47')
];

export const server_loads = [0,3];

export const dictionary = {
		"/": [~4],
		"/about-us": [6],
		"/admin": [7],
		"/allianz": [~8],
		"/annual-travel-insurance": [~9],
		"/api/payment-intent/validate": [~10],
		"/axa-policy-check": [~12],
		"/axa": [~11],
		"/become-a-partner": [13],
		"/confirmation/[cart_id]": [~14],
		"/embassies-consulates": [15],
		"/error": [16],
		"/extend/[cart_id]": [~17],
		"/extend/[cart_id]/expired": [18],
		"/extend/[cart_id]/link-expired": [19],
		"/extend/[cart_id]/payment": [~20],
		"/healix-policy-check": [~21],
		"/international-travel-insurance": [~22],
		"/login": [23],
		"/long-stay-travel-insurance": [~24],
		"/multi-trip-travel-insurance": [~25],
		"/mutuaide-policy-check": [~27],
		"/mutuaide": [~26],
		"/payment/[cart_id]": [~28],
		"/quotation/[cart_id]": [~29],
		"/redirect": [30],
		"/schengen-travel-insurance": [~31],
		"/travel-guide": [~32,[2]],
		"/travel-guide/author/[slug]": [~34,[2]],
		"/travel-guide/category/[slug]": [~35,[2]],
		"/travel-guide/manage": [36,[2]],
		"/travel-guide/manage/preview": [37,[2,3]],
		"/travel-guide/manage/preview/page/[slug]": [~38,[2,3]],
		"/travel-guide/manage/preview/post/[slug]": [~39,[2,3]],
		"/travel-guide/search": [40,[2]],
		"/travel-guide/tags/[slug]": [~41,[2]],
		"/travel-guide/[slug]": [~33,[2]],
		"/travel-insurance-australia": [~42],
		"/travel-insurance-new-zealand": [~43],
		"/travel-insurance-uk": [~44],
		"/travel-medical-insurance": [~45],
		"/visitors-insurance": [~46],
		"/your-details/[cart_id]": [~47],
		"/[slug]": [~5]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: universal_hooks.reroute || (() => {}),
	transport: universal_hooks.transport || {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';